/**
 *
 * @param pathImage
 * @param text
 * @param className string doit être une classe tailwind
 * @returns {JSX.Element}
 * @constructor
 */
const LabelFooter = ({pathImage, text, className}) => {
    let finalClass = "flex text-white items-center gap-x-3 sm:block ";

    if (typeof  className === "string") {
        finalClass += className
    }

    return (
      <div className={finalClass}>
          <img src={pathImage}  alt="A society label" className="mb-1 w-14 h-auto sm:mx-auto"/>
          <p className="text-xs md:text-center">{text}</p>
      </div>
    );
}

export default LabelFooter;