import {Link, useLocation} from 'react-router-dom';
import Logo from '../assets/logo_pretup_blanc.svg';
import LogoLemonway from '../assets/logo_lemonway.svg';
import LogoVerifiedVisa from '../assets/logo_verified_visa.svg';
import LogoVerifiedMasterCard from '../assets/logo_verified_mastercard.svg';
import {FaFacebook, FaInstagram, FaLinkedin, FaYoutube} from "react-icons/fa";
import {FaXTwitter} from "react-icons/fa6";
import {getMenu} from "../services/newApi";
import MenuFooter from "./MenuFooter";
import {footerMenuStaticItems} from "../constants/FooterMenuItems";
import Button from "./ui/buttons/Button";
import LabelFooter from "./LabelFooter";
import {useQuery} from "@tanstack/react-query";

const Footer = () => {
    const location = useLocation();
    const currentYear = new Date().getFullYear();
    let lendingCrmItems = [];
    let borrowingCrmItems = [];
    let pretupCrmItems = [];
    const { data } = useQuery({ queryKey: ["footer-menu"], queryFn: getMenu});
    const userType = location.pathname === '/emprunteur' ? 'emprunteur' : 'preteur';

    if (data) {
        lendingCrmItems = data?.filter(item => item.titreRubrique === "Prêter") || [];
        borrowingCrmItems = data?.filter(item => item.titreRubrique === "Emprunter") || [];
        pretupCrmItems = data?.filter(item => item.titreRubrique === "Pretup ?") || [];
    }

    return (
        <footer className={`rounded ${userType === 'emprunteur' ? 'bg-primaryDark' : 'bg-secondaryDark'}`}>
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="md:flex md:justify-between md:items-center">
                    <div className="mb-6 md:mb-0">
                        <a href="" className="flex items-center">
                            <img src={Logo} className="h-8 me-3" alt="PretUp Logo"/>
                        </a>
                    </div>
                    <div className="mb-4 md:mb-0 text-white sm:flex items-center">
                        <p className="mb-2 sm:mb-0 mr-3">Nouveau sur PretUp ?</p>
                        <Button text="Inscrivez-vous"
                                size="small"
                                btnType="secondaryBorder"
                                tailwinClass="text-secondary bg-white border-2 border-secondaryLight transition hover:scale-105"
                                link={{external: false, href: '/inscription/preteur'}}
                        />
                    </div>
                    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-2 sm:gap-6 sm:items-center">
                        {/*<div>*/}
                        <h2 className={`text-sm font-semibold uppercase text-white`}>
                            <Link to="/a-propos-de" className="hover:underline">A propos de nous</Link>
                        </h2>
                        {/*</div>*/}
                        {/*<div>*/}
                        <h2 className={`text-sm font-semibold uppercase text-white`}>
                            <Link to="/partenaires" className="hover:underline">Nos partenaires</Link>
                        </h2>
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*    <h2 className={`text-sm font-semibold uppercase ${userType === 'emprunteur' ? 'text-white' : 'text-white'}`}>*/}
                        {/*        <Link href="/fiscalite" className="hover:underline">Fiscalité</Link>*/}
                        {/*    </h2>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        <h2 className={`text-sm font-semibold uppercase text-white`}>
                            <Link to="/nous-recrutons" className="hover:underline">Nous recrutons</Link>
                        </h2>
                        {/*</div>*/}
                    </div>
                </div>
                <hr className={`my-6 border-white sm:mx-auto lg:my-8`}/>
                <div className="flex flex-col gap-4 sm:flex-row sm:justify-between">
                    <div className="mb-4 sm:mb-0">
                        <Button
                            link={{external: false, href: '/contact'}}
                            text="Contactez-nous"
                            size="small"
                            btnType="secondaryBorder"
                            tailwinClass="mb-4 w-44 text-secondary bg-white border-2 border-secondaryLight transition hover:scale-105 !block"
                        />
                        <Button
                            link={{external: true, href: 'https://calendly.com/pretup/rdv', target: "_blank"}}
                            text="Prendre un RDV"
                            size="small"
                            btnType="secondaryBorder"
                            tailwinClass="mb-4 w-44 block text-secondary bg-white border-2 border-secondaryLight transition hover:scale-105 !block"
                            className={""}
                        />
                        <div>
                            <p className="text-sm text-white">62 Avenue Foch</p>
                            <p className="text-sm text-white">54000 Nancy</p>
                            <p className="text-sm text-white">
                                <a href="tel:03-83-41-64-52">03.83.41.64.52</a>
                            </p>
                            <p className="text-sm text-white">
                                <a href="mailto:contact@pretup.fr">contact@pretup.fr</a>
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-4 gap-8 sm:mb-0 md:flex-nowrap md:mb-0">
                        {lendingCrmItems.length > 0 && <MenuFooter items={lendingCrmItems} title="Préter"/>}
                        <div>
                            {borrowingCrmItems.length > 0 &&
                                <MenuFooter items={borrowingCrmItems} tailwindStyle={{mainContainer: "mb-10"}}
                                            title="Emprunter"/>}
                            <MenuFooter items={footerMenuStaticItems} title="Liens utiles"/>
                        </div>
                        {pretupCrmItems.length > 0 && <MenuFooter items={pretupCrmItems} title="Pretup ?"/>}
                    </div>


                    <div className="self-start md:max-w-32">
                        <h3 className={`text-sm  font-semibold uppercase text-white mb-2 md:text-center`}>Suivez-nous</h3>
                        <div className="mb-4 flex gap-x-2 md:justify-center">
                            <a href="https://www.facebook.com/PlateformeFinTechPretUp/?locale=fr_FR"
                               className="text-white hover:text-gray-500" target="_blank" rel="noopener noreferrer">
                                <FaFacebook/>
                                <span className="sr-only">Facebook</span>
                            </a>
                            <a href="https://twitter.com/Pret_Up?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                               className="text-white hover:text-gray-500" target="_blank" rel="noopener noreferrer">
                                <FaXTwitter/>
                                <span className="sr-only">Twitter</span>
                            </a>
                            <a href="https://www.linkedin.com/company/pretup/?originalSubdomain=fr"
                               className="text-white hover:text-gray-500" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin/>
                                <span className="sr-only">Linkedin</span>
                            </a>
                            <a href="https://www.instagram.com/pretup.fr/"
                               className="text-white hover:text-gray-500" target="_blank" rel="noopener noreferrer">
                                <FaInstagram/>
                                <span className="sr-only">Instagram</span>
                            </a>
                            <a href="https://www.youtube.com/@PretupFr"
                               className="text-white hover:text-gray-500" target="_blank" rel="noopener noreferrer">
                                <FaYoutube />
                                <span className="sr-only">Instagram</span>
                            </a>
                        </div>
                        <div>
                            <LabelFooter
                                pathImage="/images/icons/ifp.webp"
                                text="Plateforme de financement participatif agréée en tant que Prestataire de Services de Financement Participatif (PSFP)"
                                className="mt-8 mb-4"
                            />
                            <LabelFooter
                                pathImage="/images/icons/membership-badge.png"
                                text="PretUp est membre de l'association France Fintech"
                                className="mt-8"
                            />
                        </div>
                    </div>
                </div>
                <hr className={`my-6 ${userType === 'emprunteur' ? 'border-white' : 'border-white'} sm:mx-auto lg:my-8`}/>
                <div className="flex justify-center">
                    <p className={`text-center text-sm ${userType === 'emprunteur' ? 'text-gray-500' : 'text-gray-500'}`}>
                        Unitup est enregistrée sous l'identiﬁant 80944 par l’Autorité de Contrôle et de Résolution
                        (ACPR) comme agent prestataire de services de paiement de Lemonway (établissement de paiement
                        dont le siège social est situé au 8 rue du Sentier, 75002 Paris, agréé par l’ACPR sous le numéro
                        16568). <br/>
                        Unitup, marques commerciales PretUp et Unilend, est agrée en tant que Prestataire de Services de
                        Financement Participatif (PSFP), agréée par l'Autorité des Marchés Financiers (AMF) sous le
                        n°FP-2024-7. <br/>
                        <p className="font-semibold">Attention : prêter de l'argent aux PME/TPE présente un risque de
                            non remboursement et de perte en capital.</p>
                    </p>
                </div>
                <div className="flex justify-center items-center space-x-4 mt-4 h-16">
                    <img src={LogoLemonway} className="partner-logos h-24" alt="Lemonway Logo"/>
                    <img src={LogoVerifiedVisa} className="partner-logos h-16" alt="Verified Visa Logo"/>
                    <img src={LogoVerifiedMasterCard} className="partner-logos h-16" alt="Verified MasterCard Logo"/>
                </div>
                <div className="flex justify-center mt-4">
                    <p className={`text-center text-sm ${userType === 'emprunteur' ? 'text-gray-500' : 'text-gray-500'}`}>
                        © {currentYear} PretUp. All Rights Reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;